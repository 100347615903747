import Decimal from "decimal.js";

import {
  ClearingAccountRestrictionType,
  DirectIndexTrackingPreference,
  DirectIndexType,
  GicsCode,
  PerformanceDataPeriod,
  SectorMapping,
  StockIndex,
  TaxFilingStatus,
} from "../generated/graphql";
import { DocumentLinks } from "./documentLinks";

// Higher LHF causes the algorithm to care more about losses and less about
// matching the target allocations simulations (see notion page) indicate a
// factor of 0.03 strikes a good balance.
export const DEFAULT_DIRECT_INDEXING_LOSS_HARVESTING_FACTOR = new Decimal(2.5);

// Trigger full liquidation in case liquidation will take portfolio value below this threshold
export const MIN_PORTFOLIO_VALUE_AFTER_LIQUIDATION = new Decimal(2000); // $2000

// The general minimum should be the lowest of the minimums for all direct index types.
// It's intended for logged-out copy.
export const GENERAL_MINIMUM_FOR_DIRECT_INDEXING = new Decimal(20000);
export const GENERAL_MINIMUM_FOR_LARGE_DIRECT_INDICES = new Decimal(50000);
export const DirectIndexingMinInvestmentMap: Record<DirectIndexType, number> = {
  [DirectIndexType.CrspIssLargeCapEsg]: 20000,
  [DirectIndexType.CrspLargeCap]: 20000,
  [DirectIndexType.CrspMidCap]: 20000,
  [DirectIndexType.CrspSmallCap]: 50000,
  [DirectIndexType.CrspTotalMarket]: 50000,

  [DirectIndexType.Russell_1000]: 50000,
  [DirectIndexType.Russell_2000]: 50000,
  [DirectIndexType.Russell_3000]: 50000,

  [DirectIndexType.Smh]: 20000,

  [DirectIndexType.Sp1500]: 50000,
  [DirectIndexType.Sp500]: 20000,
  [DirectIndexType.SpAdrDm]: 20000,
  [DirectIndexType.SpAdrEm]: 20000,
  [DirectIndexType.SpInfoTech]: 20000,
  [DirectIndexType.SpShariah]: 20000,

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: 20000,
  [DirectIndexType.CustomMarketCap]: 20000,
  [DirectIndexType.EtfBased]: 20000,
};

// Display name for the direct index type
export const DirectIndexingTypeText: Record<DirectIndexType, string> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]: "CRSP ISS US Large Cap ESG",
  [DirectIndexType.CrspLargeCap]: "CRSP US Large Cap",
  [DirectIndexType.CrspMidCap]: "CRSP US Mid Cap",
  [DirectIndexType.CrspSmallCap]: "CRSP US Small Cap",
  [DirectIndexType.CrspTotalMarket]: "CRSP US Total Market",

  [DirectIndexType.Russell_1000]: "Russell 1000",
  [DirectIndexType.Russell_2000]: "Russell 2000",
  [DirectIndexType.Russell_3000]: "Russell 3000",

  [DirectIndexType.Smh]: "MVIS® US Listed Semiconductor 25",

  [DirectIndexType.Sp1500]: "S&P Composite 1500®",
  [DirectIndexType.Sp500]: "S&P 500®",
  [DirectIndexType.SpAdrDm]: "S&P Developed Markets ADR",
  [DirectIndexType.SpAdrEm]: "S&P Emerging ADR",
  [DirectIndexType.SpInfoTech]: "S&P 500® Information Technology",
  [DirectIndexType.SpShariah]: "S&P 500® Shariah",

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: "Custom Fixed Weight",
  [DirectIndexType.CustomMarketCap]: "Custom Market Cap",
  [DirectIndexType.EtfBased]: "ETF Based",
};

// Short display name for the direct index type
export const DirectIndexingTypeShortText: Record<DirectIndexType, string> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]: "CRSP ISS US Large Cap ESG",
  [DirectIndexType.CrspLargeCap]: "CRSP US Large Cap",
  [DirectIndexType.CrspMidCap]: "CRSP US Mid Cap",
  [DirectIndexType.CrspSmallCap]: "CRSP US Small Cap",
  [DirectIndexType.CrspTotalMarket]: "CRSP US Total Market",

  [DirectIndexType.Russell_1000]: "Russell 1000",
  [DirectIndexType.Russell_2000]: "Russell 2000",
  [DirectIndexType.Russell_3000]: "Russell 3000",

  [DirectIndexType.Smh]: "MVIS US Semiconductor",

  [DirectIndexType.Sp1500]: "S&P Composite 1500",
  [DirectIndexType.Sp500]: "S&P 500",
  [DirectIndexType.SpAdrDm]: "S&P Developed Markets ADR",
  [DirectIndexType.SpAdrEm]: "S&P Emerging ADR",
  [DirectIndexType.SpInfoTech]: "S&P 500 Info Tech",
  [DirectIndexType.SpShariah]: "S&P 500 Shariah",

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: "Custom Fixed Weight",
  [DirectIndexType.CustomMarketCap]: "Custom Market Cap",
  [DirectIndexType.EtfBased]: "ETF Based",
};

// Description for the direct index type
export const DirectIndexingTypeDescription: Record<DirectIndexType, string> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]:
    "The CRSP ISS US Large Cap ESG Index measures the performance of large-cap US companies with strong environmental, social, and governance (ESG) practices as ranked by Institutional Shareholder Services Inc. (ISS). It is designed to provide exposure to companies that meet specific ISS ESG criteria, making it a popular choice for investors wanting to invest in companies focused on sustainability. The index contains the top half of companies in CRSP Large Cap based on their ISS ESG Performance scores assessing the environmental, social, and governance performance of the corporate issuers.",
  [DirectIndexType.CrspLargeCap]:
    "The CRSP US Large Cap index tracks the performance of the largest segment of the US market, including mega-cap and mid-cap companies. It includes the top 85% of market cap across sectors. The Vanguard Large-Cap ETF (VV) tracks this index.",
  [DirectIndexType.CrspMidCap]:
    "The CRSP US Mid Cap index tracks the performance of US mid cap stocks. It targets US companies that fall between the top 70%-85% of investable market capitalization. The Vanguard Mid-Cap ETF (VO) tracks this index.",
  [DirectIndexType.CrspSmallCap]:
    "The CRSP US Small Cap index measures the performance of US Companies that fall in the bottom 2-15% of the investable market capitalization. It includes securities traded on NYSE, NYSE Market, NASDAQ or ARCA. The Vanguard Small-Cap ETF (VB) tracks this index.",
  [DirectIndexType.CrspTotalMarket]:
    "The CRSP US Total Market Index tracks the performance of the entire US stock market, providing comprehensive exposure across all market capitalizations. This index includes large, mid, small, and micro-cap stocks, covering nearly 100% of the investable US equity market. The Vanguard Total Stock Market ETF (VTI) tracks this index.",

  [DirectIndexType.Russell_1000]:
    "The Russell 1000® Index measures the performance of the large-cap segment of the US equity universe. It is a subset of the Russell 3000® Index and includes approximately 1,000 of the largest securities based on a combination of their market cap and current index membership. The Russell 1000 represents approximately 93% of the Russell 3000® Index, as of the most recent reconstitution. The Russell 1000® Index is constructed to provide a comprehensive and unbiased barometer for the large-cap segment and is completely reconstituted annually to ensure new and growing equities are included.",
  [DirectIndexType.Russell_2000]:
    "The Russell 2000® Index measures the performance of the small-cap segment of the US equity universe. The Russell 2000 Index is a subset of the Russell 3000® Index representing approximately 7% of the total market capitalization of that index, as of the most recent reconstitution. It includes approximately 2,000 of the smallest securities based on a combination of their market cap and current index membership. The Russell 2000 is constructed to provide a comprehensive and unbiased small-cap barometer and is completely reconstituted annually to ensure larger stocks do not distort the performance and characteristics of the true small-cap opportunity set.",
  [DirectIndexType.Russell_3000]:
    "The Russell 3000® Index measures the performance of the largest 3,000 US companies representing approximately 96% of the investable US equity market, as of the most recent reconstitution. The Russell 3000 Index is constructed to provide a comprehensive, unbiased and stable barometer of the broad market and is completely reconstituted annually to ensure new and growing equities are included.",

  [DirectIndexType.Smh]:
    "The MVIS® US Listed Semiconductor 25 Index (MVSMH) tracks the performance of the 25 largest and most liquid US exchange-listed companies in the semiconductor industry. This is a modified market cap-weighted index, and only includes companies that generate at least 50% of their revenue from semiconductors or semiconductor equipment. SMH, the popular semiconductor ETF, tracks this index.",

  [DirectIndexType.Sp1500]:
    "The S&P Composite 1500® is a broad market index that combines the S&P 500®, S&P MidCap 400®, and S&P SmallCap 600® indices. It covers approximately 90% of U.S. market capitalization and provides a comprehensive measure of large, mid, and small-cap equities.",
  [DirectIndexType.Sp500]:
    "The S&P 500® is widely regarded as the best single gauge of large-cap U.S. equities. The index includes 500 leading companies and covers approximately 80% of available market capitalization.",
  [DirectIndexType.SpAdrDm]:
    "The S&P Developed Markets ADR Index seeks to track all American depositary receipts trading on the NYSE, NYSE American, and NASDAQ that represent shares in companies from developed markets, subject to size and liquidity requirements.",
  [DirectIndexType.SpAdrEm]:
    "The S&P Emerging ADR Index seeks to track all American depositary receipts trading on the NYSE, NYSE American, and NASDAQ that represent shares in companies from emerging markets, subject to size and liquidity requirements.",
  [DirectIndexType.SpInfoTech]:
    "The S&P 500® Information Technology comprises those companies included in the S&P 500 that are classified as members of the GICS® information technology sector.",
  [DirectIndexType.SpShariah]:
    "The S&P 500® Shariah includes all Shariah-compliant constituents of the S&P 500, the leading benchmark for the U.S. equity market.",

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: "Custom Fixed Weight",
  [DirectIndexType.CustomMarketCap]: "Custom Market Cap",
  [DirectIndexType.EtfBased]: "ETF Based",
};

export const DirectIndexingTypeFunFact: Record<DirectIndexType, string> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]:
    "A sustainability index that focuses on companies with strong environmental, social, and governance practices",
  [DirectIndexType.CrspLargeCap]:
    "Vanguard’s popular large-cap ETF, VV, tracks this CRSP index",
  [DirectIndexType.CrspMidCap]:
    "Vanguard’s popular mid-cap ETF, VO, tracks this CRSP index",
  [DirectIndexType.CrspSmallCap]:
    "Vanguard’s popular small-cap ETF, VB, tracks this CRSP index",
  [DirectIndexType.CrspTotalMarket]:
    "Vanguard's popular total market index ETF, VTI, tracks this CRSP index",
  [DirectIndexType.Russell_1000]:
    "Tracks the largest 1.000 companies in the US",
  [DirectIndexType.Russell_2000]: "Tracks 2,000 small-cap US companies",
  [DirectIndexType.Russell_3000]:
    "Tracks the top 3.000 companies in the US across market caps",
  [DirectIndexType.Smh]:
    "Tracks top companies in the semiconductor industry, tracked by the popular ETF SMH",
  [DirectIndexType.Sp1500]:
    "Popular ETFs like ITOT and SPTM track the S&P Composite 1500",
  [DirectIndexType.Sp500]:
    "Popular ETFs like SPY, IVV, and VOO track the S&P 500",
  [DirectIndexType.SpAdrDm]:
    "Tracks the developed international market excluding the US and Canada",
  [DirectIndexType.SpAdrEm]: "Tracks the emerging international market",
  [DirectIndexType.SpInfoTech]:
    "Compare this to technology focused ETFs like XLK",
  [DirectIndexType.SpShariah]: "Tracks Shariah-compliant companies in the US",

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: "Custom Fixed Weight",
  [DirectIndexType.CustomMarketCap]: "Custom Market Cap",
  [DirectIndexType.EtfBased]: "ETF Based",
};

export const DirectIndexingTypeBenchmarkLink: Record<DirectIndexType, string> =
  {
    // Prod types
    [DirectIndexType.CrspIssLargeCapEsg]: DocumentLinks["ESG Benchmark"],
    [DirectIndexType.CrspLargeCap]: DocumentLinks["CRSP Benchmarks"],
    [DirectIndexType.CrspMidCap]: DocumentLinks["CRSP Benchmarks"],
    [DirectIndexType.CrspSmallCap]: DocumentLinks["CRSP Benchmarks"],
    [DirectIndexType.CrspTotalMarket]: DocumentLinks["CRSP Benchmarks"],
    [DirectIndexType.Russell_1000]: DocumentLinks["Russell Benchmarks"],
    [DirectIndexType.Russell_2000]: DocumentLinks["Russell Benchmarks"],
    [DirectIndexType.Russell_3000]: DocumentLinks["Russell Benchmarks"],
    [DirectIndexType.Smh]: DocumentLinks["SMH Benchmark"],
    [DirectIndexType.Sp1500]: DocumentLinks["SP Benchmarks"],
    [DirectIndexType.Sp500]: DocumentLinks["SP Benchmarks"],
    [DirectIndexType.SpAdrDm]: DocumentLinks["ADR DM Benchmark"],
    [DirectIndexType.SpAdrEm]: DocumentLinks["ADR EM Benchmark"],
    [DirectIndexType.SpInfoTech]: DocumentLinks["SP Benchmarks"],
    [DirectIndexType.SpShariah]: DocumentLinks["Shariah Benchmark"],

    // Internal testing types
    [DirectIndexType.CustomFixedWeight]: "Custom Fixed Weight",
    [DirectIndexType.CustomMarketCap]: "Custom Market Cap",
    [DirectIndexType.EtfBased]: "ETF Based",
  };

export const DirectIndexingTypeExternalLink = new Map([
  [
    DirectIndexType.CrspIssLargeCapEsg,
    "https://www.crsp.org/indexes/crsp-iss-us-large-cap-esg-index/",
  ],
  [
    DirectIndexType.CrspLargeCap,
    "https://www.crsp.org/indexes/crsp-us-large-cap-index/",
  ],
  [
    DirectIndexType.CrspMidCap,
    "https://www.crsp.org/indexes/crsp-us-mid-cap-index/",
  ],
  [
    DirectIndexType.CrspSmallCap,
    "https://www.crsp.org/indexes/crsp-us-small-cap-index/",
  ],
  [
    DirectIndexType.CrspTotalMarket,
    "https://www.crsp.org/indexes/crsp-us-total-market-index/",
  ],
  [
    DirectIndexType.Russell_1000,
    "https://research.ftserussell.com/Analytics/FactSheets/Home/DownloadSingleIssue?issueName=US1000USD&isManual=True",
  ],
  [
    DirectIndexType.Russell_2000,
    "https://research.ftserussell.com/Analytics/FactSheets/Home/DownloadSingleIssue?issueName=US2000USD&isManual=True",
  ],
  [
    DirectIndexType.Russell_3000,
    "https://research.ftserussell.com/Analytics/FactSheets/Home/DownloadSingleIssue?issueName=US3000USD&isManual=True",
  ],
  [
    DirectIndexType.Smh,
    "https://www.marketvector.com/indexes/sector/mvis-us-listed-semiconductor-25",
  ],
  [
    DirectIndexType.Sp1500,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-composite-1500/#overview",
  ],
  [
    DirectIndexType.Sp500,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-500/#overview",
  ],
  [
    DirectIndexType.SpAdrDm,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-developed-markets-adr-index/#overview",
  ],
  [
    DirectIndexType.SpAdrEm,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-emerging-adr-index/#overview",
  ],
  [
    DirectIndexType.SpInfoTech,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-500-information-technology-sector/#overview",
  ],
  [
    DirectIndexType.SpShariah,
    "https://www.spglobal.com/spdji/en/indices/equity/sp-500-shariah-index/#overview",
  ],
]);

export const DirectIndexingTypeTags: Map<DirectIndexType, string[]> = new Map([
  [
    DirectIndexType.SpAdrDm,
    [
      "international",
      "canada",
      "europe",
      "asia",
      "united kingdom",
      "japan",
      "netherlands",
      "denmark",
      "switzerland",
      "france",
      "germany",
      "spain",
      "australia",
      "belgium",
      "italy",
      "israel",
      "norway",
      "singapore",
      "sweden",
      "finland",
      "ireland",
      "luxembourg",
      "hong kong",
    ],
  ],
  [DirectIndexType.SpShariah, ["halal"]],
  [DirectIndexType.Smh, ["united states", "taiwan", "netherlands"]],
]);

// Symbol of the comparable ETF for the direct index type
export const DirectIndexTypeToETFMap: Record<
  DirectIndexType,
  { symbol: string; expenseRatioPercent: Decimal }
> = {
  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: {
    symbol: "SPY",
    expenseRatioPercent: new Decimal(0.09),
  },
  [DirectIndexType.CustomMarketCap]: {
    symbol: "SPY",
    expenseRatioPercent: new Decimal(0.09),
  },
  [DirectIndexType.EtfBased]: {
    symbol: "SPY",
    expenseRatioPercent: new Decimal(0.09),
  },

  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]: {
    symbol: "ESGU",
    expenseRatioPercent: new Decimal(0.15),
  },
  [DirectIndexType.CrspLargeCap]: {
    symbol: "VV",
    expenseRatioPercent: new Decimal(0.04),
  },
  [DirectIndexType.CrspMidCap]: {
    symbol: "VO",
    expenseRatioPercent: new Decimal(0.04),
  },
  [DirectIndexType.CrspSmallCap]: {
    symbol: "VB",
    expenseRatioPercent: new Decimal(0.05),
  },
  [DirectIndexType.CrspTotalMarket]: {
    symbol: "VTI",
    expenseRatioPercent: new Decimal(0.03),
  },

  [DirectIndexType.Russell_1000]: {
    symbol: "IWB",
    expenseRatioPercent: new Decimal(0.15),
  },
  [DirectIndexType.Russell_2000]: {
    symbol: "IWM",
    expenseRatioPercent: new Decimal(0.19),
  },
  [DirectIndexType.Russell_3000]: {
    symbol: "IWV",
    expenseRatioPercent: new Decimal(0.2),
  },

  [DirectIndexType.Smh]: {
    symbol: "SMH",
    expenseRatioPercent: new Decimal(0.35),
  },

  [DirectIndexType.Sp1500]: {
    symbol: "SPTM",
    expenseRatioPercent: new Decimal(0.03),
  },
  [DirectIndexType.Sp500]: {
    symbol: "SPY",
    expenseRatioPercent: new Decimal(0.09),
  },
  [DirectIndexType.SpAdrDm]: {
    symbol: "EFA",
    expenseRatioPercent: new Decimal(0.07),
  },
  [DirectIndexType.SpAdrEm]: {
    symbol: "IEMG",
    expenseRatioPercent: new Decimal(0.09),
  },
  [DirectIndexType.SpInfoTech]: {
    symbol: "XLK",
    expenseRatioPercent: new Decimal(0.09),
  },
  [DirectIndexType.SpShariah]: {
    symbol: "SPUS",
    expenseRatioPercent: new Decimal(0.45),
  },
};

// Full name of the comparable ETF for the direct index type
export const DirectIndexTypeToComparisonETFNameMap: Record<
  DirectIndexType,
  string
> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]: "iShares ESG Aware MSCI USA ETF",
  [DirectIndexType.CrspLargeCap]: "Vanguard Large Cap Index Fund",
  [DirectIndexType.CrspMidCap]: "Vanguard Mid Cap Index Fund",
  [DirectIndexType.CrspSmallCap]: "Vanguard Small Cap Index Fund",
  [DirectIndexType.CrspTotalMarket]: "Vanguard Total Stock Market Index Fund",

  [DirectIndexType.Russell_1000]: "iShares Russell 1000 ETF",
  [DirectIndexType.Russell_2000]: "iShares Russell 2000 ETF",
  [DirectIndexType.Russell_3000]: "iShares Russell 3000 ETF",

  [DirectIndexType.Smh]: "VanEck Semiconductor ETF",

  [DirectIndexType.Sp1500]: "SPDR S&P 1500 Composite Stock Market ETF",
  [DirectIndexType.Sp500]: "SPDR S&P 500 ETF Trust",
  [DirectIndexType.SpAdrDm]: "iShares MSCI EAFE ETF",
  [DirectIndexType.SpAdrEm]: "iShares Core MSCI Emerging Markets ETF",
  [DirectIndexType.SpInfoTech]: "Technology Select Sector SPDR Fund",
  [DirectIndexType.SpShariah]:
    "SP Funds S&P 500 Sharia Industry Exclusions ETF",

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: "SPDR S&P 500 ETF Trust",
  [DirectIndexType.CustomMarketCap]: "SPDR S&P 500 ETF Trust",
  [DirectIndexType.EtfBased]: "SPDR S&P 500 ETF Trust",
};

// Sub text in explorer tiles
export const DirectIndexTypeToIndexSubTextMap: Record<DirectIndexType, string> =
  {
    // Prod types
    [DirectIndexType.CrspIssLargeCapEsg]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CrspIssLargeCapEsg].symbol
    }, ${
      DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CrspIssLargeCapEsg]
    }`,
    [DirectIndexType.CrspLargeCap]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CrspLargeCap].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CrspLargeCap]}`,
    [DirectIndexType.CrspMidCap]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CrspMidCap].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CrspMidCap]}`,
    [DirectIndexType.CrspSmallCap]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CrspSmallCap].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CrspSmallCap]}`,
    [DirectIndexType.CrspTotalMarket]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CrspTotalMarket].symbol
    }, ${
      DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CrspTotalMarket]
    }`,

    [DirectIndexType.Russell_1000]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Russell_1000].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Russell_1000]}`,
    [DirectIndexType.Russell_2000]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Russell_2000].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Russell_2000]}`,
    [DirectIndexType.Russell_3000]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Russell_3000].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Russell_3000]}`,

    [DirectIndexType.Smh]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Smh].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Smh]}`,

    [DirectIndexType.Sp1500]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Sp1500].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Sp1500]}`,
    [DirectIndexType.Sp500]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.Sp500].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.Sp500]}`,
    [DirectIndexType.SpAdrDm]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.SpAdrDm].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.SpAdrDm]}`,
    [DirectIndexType.SpAdrEm]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.SpAdrEm].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.SpAdrEm]}`,
    [DirectIndexType.SpInfoTech]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.SpInfoTech].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.SpInfoTech]}`,
    [DirectIndexType.SpShariah]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.SpShariah].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.SpShariah]}`,

    // Internal testing types
    [DirectIndexType.CustomFixedWeight]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CustomFixedWeight].symbol
    }, ${
      DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CustomFixedWeight]
    }`,
    [DirectIndexType.CustomMarketCap]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.CustomMarketCap].symbol
    }, ${
      DirectIndexTypeToComparisonETFNameMap[DirectIndexType.CustomMarketCap]
    }`,
    [DirectIndexType.EtfBased]: `Comparable to ${
      DirectIndexTypeToETFMap[DirectIndexType.EtfBased].symbol
    }, ${DirectIndexTypeToComparisonETFNameMap[DirectIndexType.EtfBased]}`,
  };

// Maps DI type to Stock Index
export const DirectIndexTypeToStockIndexMapping = new Map([
  [DirectIndexType.CrspIssLargeCapEsg, StockIndex.CrspIssLargeCapEsg],
  [DirectIndexType.CrspLargeCap, StockIndex.CrspLargeCap],
  [DirectIndexType.CrspMidCap, StockIndex.CrspMidCap],
  [DirectIndexType.CrspSmallCap, StockIndex.CrspSmallCap],
  [DirectIndexType.CrspTotalMarket, StockIndex.CrspTotalMarket],
  [DirectIndexType.Russell_1000, StockIndex.Russell_1000],
  [DirectIndexType.Russell_2000, StockIndex.Russell_2000],
  [DirectIndexType.Russell_3000, StockIndex.Russell_3000],
  [DirectIndexType.Smh, StockIndex.Smh],
  [DirectIndexType.Sp1500, StockIndex.Sp1500],
  [DirectIndexType.Sp500, StockIndex.Sp500],
  [DirectIndexType.SpAdrDm, StockIndex.SpAdrDm],
  [DirectIndexType.SpAdrEm, StockIndex.SpAdrEm],
  [DirectIndexType.SpInfoTech, StockIndex.Sp500_45],
  [DirectIndexType.SpShariah, StockIndex.SpShariah],
]);

// Maps Stock Index to  DI type
export const StockIndexToDirectIndexTypeMapping = new Map([
  [StockIndex.CrspIssLargeCapEsg, DirectIndexType.CrspIssLargeCapEsg],
  [StockIndex.CrspLargeCap, DirectIndexType.CrspLargeCap],
  [StockIndex.CrspMidCap, DirectIndexType.CrspMidCap],
  [StockIndex.CrspSmallCap, DirectIndexType.CrspSmallCap],
  [StockIndex.CrspTotalMarket, DirectIndexType.CrspTotalMarket],
  [StockIndex.Russell_1000, DirectIndexType.Russell_1000],
  [StockIndex.Russell_2000, DirectIndexType.Russell_2000],
  [StockIndex.Russell_3000, DirectIndexType.Russell_3000],
  [StockIndex.Smh, DirectIndexType.Smh],
  [StockIndex.Sp1500, DirectIndexType.Sp1500],
  [StockIndex.Sp500, DirectIndexType.Sp500],
  [StockIndex.SpAdrDm, DirectIndexType.SpAdrDm],
  [StockIndex.SpAdrEm, DirectIndexType.SpAdrEm],
  [StockIndex.Sp500_45, DirectIndexType.SpInfoTech],
  [StockIndex.SpShariah, DirectIndexType.SpShariah],
]);

export const DirectIndexingCanCustomizeSectors: Record<
  DirectIndexType,
  boolean
> = {
  // Prod types
  [DirectIndexType.CrspIssLargeCapEsg]: true,
  [DirectIndexType.CrspLargeCap]: true,
  [DirectIndexType.CrspMidCap]: true,
  [DirectIndexType.CrspSmallCap]: true,
  [DirectIndexType.CrspTotalMarket]: true,

  [DirectIndexType.Russell_1000]: true,
  [DirectIndexType.Russell_2000]: true,
  [DirectIndexType.Russell_3000]: true,

  [DirectIndexType.Sp1500]: true,
  [DirectIndexType.Sp500]: true,
  [DirectIndexType.SpAdrDm]: true,
  [DirectIndexType.SpAdrEm]: true,
  [DirectIndexType.SpInfoTech]: false,
  [DirectIndexType.SpShariah]: true,

  [DirectIndexType.Smh]: false,

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: false,
  [DirectIndexType.CustomMarketCap]: false,
  [DirectIndexType.EtfBased]: false,
};

/**
 * WARNING: This mapping is given by S&P but this is the one we use for every index on Frec.
 * Russell sectors are different, so we need to translate their sectors to this one.
 */
export const SECTORS_MAPPINGS: SectorMapping[] = [
  {
    id: "10",
    gicsCode: GicsCode.Gics10,
    name: "Energy",
  },
  {
    id: "15",
    gicsCode: GicsCode.Gics15,
    name: "Materials",
  },
  {
    id: "20",
    gicsCode: GicsCode.Gics20,
    name: "Industrials",
  },
  {
    id: "25",
    gicsCode: GicsCode.Gics25,
    name: "Consumer Discretionary",
  },
  {
    id: "30",
    gicsCode: GicsCode.Gics30,
    name: "Consumer Staples",
  },
  {
    id: "35",
    gicsCode: GicsCode.Gics35,
    name: "Health Care",
  },
  {
    id: "40",
    gicsCode: GicsCode.Gics40,
    name: "Financials",
  },
  {
    id: "45",
    gicsCode: GicsCode.Gics45,
    name: "Information Technology",
  },
  {
    id: "50",
    gicsCode: GicsCode.Gics50,
    name: "Telecommunication Services",
  },
  {
    id: "55",
    gicsCode: GicsCode.Gics55,
    name: "Utilities",
  },
  {
    id: "60",
    gicsCode: GicsCode.Gics60,
    name: "Real Estate",
  },
];

// This is the default performance period on page load
export const DEFAULT_PERFORMANCE_PERIOD_EXPLORER = PerformanceDataPeriod.Ytd;

export const INDICES_WITH_SECTORS = new Set<DirectIndexType>(
  Object.entries(DirectIndexingCanCustomizeSectors)
    .filter(([_, canCustomizeSectors]) => canCustomizeSectors)
    .map(([type, _]) => type as DirectIndexType)
);

/**
 * Used by client and server to keep the prices we show to customer in sync
 *
 * Note that pricing records are persisted to the DB, so changing this value
 * only affects new customers
 */
export const DefaultPricing = {
  directIndexBaseBPS: new Decimal(10), // 0.10%
  directIndexDiscount: {
    // -100% means free
    percent: new Decimal(0), // should be a negative number (eg, -1)
    duration: { days: 0 },
  },
} as const;

export const getDiPricingInfo = (directIndexType: DirectIndexType) => {
  switch (directIndexType) {
    case DirectIndexType.CrspIssLargeCapEsg:
      return {
        directIndexBaseBPS: new Decimal(25), // 0.25%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.CrspMidCap:
      return {
        directIndexBaseBPS: new Decimal(14), // 0.14%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.CrspSmallCap:
      return {
        directIndexBaseBPS: new Decimal(15), // 0.15%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.CrspTotalMarket:
      return {
        directIndexBaseBPS: new Decimal(13), // 0.13%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.Russell_1000:
      return {
        directIndexBaseBPS: new Decimal(22), // 0.22%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.Russell_2000:
      return {
        directIndexBaseBPS: new Decimal(26), // 0.26%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.Russell_3000:
      return {
        directIndexBaseBPS: new Decimal(27), // 0.27%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.Smh:
      return {
        directIndexBaseBPS: new Decimal(35), // 0.35%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.Sp1500:
      return {
        directIndexBaseBPS: new Decimal(13), // 0.13%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.SpInfoTech:
      return {
        directIndexBaseBPS: new Decimal(19), // 0.19%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.SpAdrDm:
      return {
        directIndexBaseBPS: new Decimal(17), // 0.17%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.SpAdrEm:
      return {
        directIndexBaseBPS: new Decimal(19), // 0.19%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    case DirectIndexType.SpShariah:
      return {
        directIndexBaseBPS: new Decimal(45), // 0.45%
        directIndexDiscount: {
          // -100% means free
          percent: new Decimal(0), // should be a negative number (eg, -1)
          duration: { days: 0 },
        },
      } as const;
    default:
      return DefaultPricing;
  }
};

// Apex has a limit on the number of lots that can be submitted as part of order
// submission
export const MAX_TAX_LOT_COUNT_SUPPORTED_IN_LOT_MATCHING = 8;

// When selling tax lots we prefer LT gains to ST gains by this factor, for
// example a factor of 2.0 here means that we prefer $10 in LT gains over $5.01
// in ST gains.
// Keep in sync with forecaster/services/direct_indexing/tlh_optimizer.py
export const SHORT_TERM_CAPITAL_GAIN_LIQUIDATION_PENALTY = new Decimal(2);

// Price fluctuation buffer during onboarding in the stock + cash mode
export const DI_ONBOARDING_PRICE_FLUCTUATION_BUFFER = new Decimal(500);

// account restrictions that prevent buys & sells
// source: go/accountstates
export const DI_INELIGIBLE_ACCOUNT_RESTRICTIONS = new Set([
  ClearingAccountRestrictionType.Closing,
  ClearingAccountRestrictionType.Closed,
  ClearingAccountRestrictionType.Frozen,
  ClearingAccountRestrictionType.Pending,
  ClearingAccountRestrictionType.ReconBreak,
  ClearingAccountRestrictionType.Rejected,
]);

export const DEFAULT_FEDERAL_INCOME_TAX_PERCENT = new Decimal(37); // 37% is the highest bracket
export const DEFAULT_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX_PERCENT = new Decimal(
  20
); // 20% is the highest bracket
export const DEFAULT_STATE_CAPITAL_GAINS_TAX_PERCENT = new Decimal(12.3); // 12.3% is the highest bracket in CA
export const DEFAULT_TAX_RATES = {
  taxFilingStatus: TaxFilingStatus.Single,
  federalIncomeTaxRate: DEFAULT_FEDERAL_INCOME_TAX_PERCENT,
  federalCapitalGainsTaxRate:
    DEFAULT_FEDERAL_LONG_TERM_CAPITAL_GAINS_TAX_PERCENT,
  stateIncomeTaxRate: DEFAULT_STATE_CAPITAL_GAINS_TAX_PERCENT,
  stateCapitalGainsTaxRate: DEFAULT_STATE_CAPITAL_GAINS_TAX_PERCENT,
} as const;

// Total number of edits allowed on sectors in DI
export const EDITABLE_NUMBER_OF_SECTORS = 2;
// Total number of edits allowed on stocks in DI
export const EDITABLE_NUMBER_OF_STOCKS = 10;

// This determine what indices show up in the most popular display category
// Last checked: August 13th 5:00 PM PT
export const DI_MOST_POPULAR_TYPES: DirectIndexType[] = [
  DirectIndexType.Sp500,
  DirectIndexType.SpInfoTech,
  DirectIndexType.CrspSmallCap,
];

// These are the indices that have a large number of stocks (>500), and therefore
// takes longer for the optimizer to run.
export const DI_LARGE_INDEX_TYPES: DirectIndexType[] = [
  DirectIndexType.CrspSmallCap,
  DirectIndexType.CrspTotalMarket,
  DirectIndexType.Russell_1000,
  DirectIndexType.Russell_2000,
  DirectIndexType.Russell_3000,
  DirectIndexType.Sp1500,
];

export enum DirectIndexAction {
  SetupWithStocks = "SetupWithStocks",
  TransferIn = "TransferIn",
  TransferOut = "TransferOut",
  EditCustomization = "EditCustomization",
  Withdraw = "Withdraw",
  TrackingPreference = "TrackingPreference",
}

export const DirectIndexActionToText: Record<DirectIndexAction, string> = {
  [DirectIndexAction.SetupWithStocks]:
    "setting up your direct index with stocks",
  [DirectIndexAction.TransferIn]: "moving stocks into your direct index",
  [DirectIndexAction.TransferOut]: "moving stocks out of your direct index",
  [DirectIndexAction.EditCustomization]: "changing your customizations",
  [DirectIndexAction.Withdraw]: "withdrawing from your direct index",
  [DirectIndexAction.TrackingPreference]: "changing your tracking preference",
};

/**
 * Loss harvesting factors for each direct index type.
 */
export const DirectIndexLHF: Record<
  DirectIndexType,
  Record<
    Exclude<
      DirectIndexTrackingPreference,
      DirectIndexTrackingPreference.Default
    >,
    number
  >
> = {
  [DirectIndexType.CrspIssLargeCapEsg]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.CrspLargeCap]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.CrspMidCap]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.CrspSmallCap]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.CrspTotalMarket]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },

  [DirectIndexType.Russell_1000]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.Russell_2000]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.Russell_3000]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },

  [DirectIndexType.Smh]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },

  [DirectIndexType.Sp1500]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.Sp500]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.SpAdrDm]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.SpAdrEm]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.SpInfoTech]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.SpShariah]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },

  // Internal testing types
  [DirectIndexType.CustomFixedWeight]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.CustomMarketCap]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
  [DirectIndexType.EtfBased]: {
    [DirectIndexTrackingPreference.LossHarvestingPriority]: 25,
    [DirectIndexTrackingPreference.TrackingPriority]: 2.5,
  },
};

export const DirectIndexTrackingPreferenceToText: Record<
  DirectIndexTrackingPreference,
  string
> = {
  [DirectIndexTrackingPreference.Default]: "Auto switch",
  [DirectIndexTrackingPreference.TrackingPriority]:
    "Prioritize tracking over harvesting tax losses",
  [DirectIndexTrackingPreference.LossHarvestingPriority]:
    "Prioritize harvesting tax losses over tracking",
};

// Used to describe each tracking preference option.
export const DirectIndexTrackingPreferenceToDescription: Record<
  DirectIndexTrackingPreference,
  string
> = {
  [DirectIndexTrackingPreference.Default]:
    "We’ll switch between prioritizing tracking vs harvesting tax losses for you",
  [DirectIndexTrackingPreference.TrackingPriority]:
    "Track the index closely while harvesting losses.",
  [DirectIndexTrackingPreference.LossHarvestingPriority]:
    "Track the index more loosely to avoid incurring capital gains while harvesting losses. Consider this if you funded a direct index with stocks that have significant gains.",
};

// Shortened description for each tracking preference type.
export const DirectIndexTrackingPreferenceToShortDescription: Record<
  DirectIndexTrackingPreference,
  string
> = {
  [DirectIndexTrackingPreference.Default]:
    "We’ll switch between prioritizing tracking vs harvesting tax losses for you",
  [DirectIndexTrackingPreference.TrackingPriority]:
    "Tracking the index closely while harvesting losses",
  [DirectIndexTrackingPreference.LossHarvestingPriority]:
    "Tracking the index more loosely to avoid incurring capital gains while harvesting losses",
};

// The description for when the tracking preference is "Default", to show whether we are
// currently prioritizing tracking or loss harvesting.
export const DirectIndexTrackingPreferenceDefaultDescription: Record<
  DirectIndexTrackingPreference,
  string
> = {
  [DirectIndexTrackingPreference.Default]:
    "We’ll switch between prioritizing tracking vs harvesting tax losses for you",
  [DirectIndexTrackingPreference.TrackingPriority]:
    "Currently prioritizing tracking your index over harvesting tax losses",
  [DirectIndexTrackingPreference.LossHarvestingPriority]:
    "Currently prioritizing harvesting tax losses over tracking your index",
};

/**
 * Given a LHF and a DirectIndexType, determine whether that LHF prioritizes tracking or tax loss harvesting.
 */
export const getTrackingPriorityType = (
  directIndexType: DirectIndexType,
  lhf?: Decimal
) => {
  // If no LHF is provided, default to tracking priority
  if (!lhf) {
    return DirectIndexTrackingPreference.TrackingPriority;
  }
  // See which of the two LHFs the given LHF is closer to
  const lhfs = DirectIndexLHF[directIndexType];
  const trackingDiff = lhf
    .minus(lhfs[DirectIndexTrackingPreference.TrackingPriority])
    .abs();
  const lossHarvestingDiff = lhf
    .minus(lhfs[DirectIndexTrackingPreference.LossHarvestingPriority])
    .abs();

  return trackingDiff.lt(lossHarvestingDiff)
    ? DirectIndexTrackingPreference.TrackingPriority
    : DirectIndexTrackingPreference.LossHarvestingPriority;
};
