export enum FeatureFlags {
  ApexMaintenanceWindow = "apex-maintenance-window",
  /**
   * FREC-3134: See https://frectalk.slack.com/archives/C05UQML76U8/p1703613215894049 for context
   */
  BypassPaymentSupportedInstitutionChecks = "bypass-payments-supported-institutions-check",
  AchTransferLimit = "ach_transfer_limit",
  BankAccountLimit = "bank_account_limit",
  ThemeToggle = "theme-toggle",
  AfterHourTrade = "after-hour-trade",
  SmhIndex = "smh-index",
  NewRepayFlow = "new-repay-flow",
  NewIndexExplorer = "new-index-explorer",
  EstimatedTaxImpact = "estimated-tax-impact",
  PortfolioAnalysis = "portfolio-analysis",
}
