import { CipCategory, UploadDocumentType } from "../generated/graphql";

export const CIP_CATEGORY_NAME_MAP = {
  [CipCategory.Address]: "Address verification document",
  [CipCategory.Dob]: "Date of birth verification document",
  [CipCategory.Name]: "Identity verification document",
  [CipCategory.TaxId]: "Tax ID verification document",
};

export const CIP_CATEGORY_DESCRIPTION_MAP: Partial<
  Record<CipCategory, string>
> = {
  [CipCategory.TaxId]:
    "Social security card, social security administration letter, IRS ITIN letter, or recent W-2.",
};

export const CIP_CATEGORY_TO_UPLOAD_DOCUMENT_TYPE = {
  [CipCategory.Address]: UploadDocumentType.CipInvestigationAddress,
  [CipCategory.Dob]: UploadDocumentType.CipInvestigationDob,
  [CipCategory.Name]: UploadDocumentType.CipInvestigationName,
  [CipCategory.TaxId]: UploadDocumentType.CipInvestigationTaxId,
};

export const CIP_CATEGORY_USER_INFO_TITLE_MAP: Record<CipCategory, string> = {
  [CipCategory.Address]: "Address you entered",
  [CipCategory.Name]: "Name",
  [CipCategory.Dob]: "Date of birth you entered",
  [CipCategory.TaxId]: "",
};

export const ADDITIONAL_DOCUMENTS_REQUIRED_TEXT =
  "Looks like we weren’t able to verify some of your information. You’ll need to upload some documents to proceed with creating your account, and once our team reviews them we’ll send you an email when your account is ready.";
